import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    padding: ${variables.sectionPadding};

    .headline {
        font-size: 1.7em;
        font-weight: 700;
        color: #262626;
        text-align: center;
    }

    .options-selector {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2em;
    }

    .layout-container {
        .control {
            margin-top: 2em;
        }

        .layout-page-tab-navigation {
            .tabs-container {
                .content {
                    .offer-boxes {
                        .offer-boxes-desktop {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .offer-boxes {
        .offer-boxes-desktop {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            flex-wrap: wrap;
            margin: 3em 0 0;

            > * {
                width: calc(33.3% - 20px);
                margin: 10px;
            }
        }
    }

    @media all and (max-width: ${variables.tabletL}) {
        .offer-boxes {
            .offer-boxes-desktop {
                flex-wrap: wrap;

                > * {
                    margin: 10px;
                    width: calc(50% - 20px);
                }
            }
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        padding: ${variables.sectionPaddingMobile};
        overflow-x: hidden;

        .offer-boxes {
            padding: 3em 0;

            .offer-boxes-desktop {
                display: none;
            }
        }

        .control {
            font-size: 130%;
        }
    }
`;
