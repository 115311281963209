import Component from './component';

Component.defaultProps = {
    children: null,
    sliderProps: {},
    customClass: '',
    showSlideInteraction: false,
    showNormalDots: false,
};

export default Component;
