import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    width: 100%;
    display: none;

    .slick-slide {
        height: inherit !important;

        & > * {
            height: 100% !important;

            & > * {
                height: 100% !important;
                display: flex;
            }
        }
    }

    .normal-dots {
		display: flex !important;
		gap: 1em;
		place-content: center;
		place-items: center;
		margin: 2em 0 0 0;

		.index-container {
			background-color: #D0BC94;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			transition: background-color 0.3s ease-in-out;

			.index {
				display: none;
			}
		}

		.slick-active {
			.index-container {
				background-color: ${variables.colorGold};
			}
		
		}
	}

    @media all and (max-width: ${variables.mobileL}) {
        display: block;
    }
`;
