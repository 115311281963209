import { FunctionComponent, useState } from 'react';
import classnames from 'classnames';

import Slick from 'components/layout/Slick';

import StyledComponent from './styles';
import { Props } from './types';

const initialSettings = {
    arrows: false,
    dots: false,
    slidesToScroll: 1,
    centerMode: true,
    adaptiveHeight: false,
    initialSlide: 1,
    slidesToShow: 1,
    infinite: true,
    autoplay: false,
};

const MobileSlider: FunctionComponent<Props> = ({
    children,
    sliderProps,
    customClass,
    showNormalDots,
}) => {
    const [slideAlreadySwiped, setSlideAlreadySwiped] = useState(false);
    let settings = {
        ...initialSettings || {},
        ...sliderProps || {},
    };

    if (showNormalDots) settings = {
        ...settings,
        dots: true,
        dotsClass: 'normal-dots',
    };

    const onSwipe = () => {
        if (!slideAlreadySwiped) {
            setSlideAlreadySwiped(true);
        }
    };

    return (
        <StyledComponent className={(classnames('layout-mobile-slider', customClass))}>
            <Slick sliderProps={{ ...settings, onSwipe }}>
                {children}
            </Slick>
        </StyledComponent>
    );
};

export default MobileSlider;
