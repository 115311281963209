import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default styled.div`
    opacity: 1;
    height: 100%;
    transition: opacity 200ms ease-in-out;

    &.opacity-0 {
        opacity: 0;
    }

    .slick-slider {
        position: relative;
        z-index: 9;
        height: 100%;

        .slick-list {
            overflow: inherit;
            overflow-x: clip;

            .slick-track {
                gap: 2em;
                display: flex;
            }
        }

        .slick-arrow {
            display: block;
            width: 9em;
            height: 10em;
            background: white;
            box-shadow: 0 0 1em 1em rgb(0 0 0 / 30%);
            padding: 1em;
            z-index: 999;
            transform: translate(0%, -50%);


            &.slick-prev {
                right: 103%;
                left: auto;
            }
            &.slick-next {
                right: auto;
                left: 96%;
            }

            &:before {
                width: 8em;
                height: 8em;
                background: white;
                box-shadow: 0 0 1.5em 0.2em rgb(0 0 0 / 10%);
                padding: 1.1em 1em 1em 1em;
                z-index: 9999;
                position: relative;
                border-radius: 36em;
                color: #CCC;
            }

            &:hover {
                &:before {
                    color: #00AEEF;
                }
            }
        }

        .slick-dots {
            display: flex !important;
            justify-content: flex-start;
            align-items: center;
            bottom: -3em;

            li {
                width: 100%;
                margin: 0;
                display: flex;
                justify-content: stretch;
                align-items: center;

                > * {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: .5em 0;
                    color: #F1F1F1;
                    font-size: .5em;
                    border-bottom: .3em solid #F1F1F1;
                }

                &.slick-active {
                    > * {
                        color: #BA9449;
                        border-bottom: .3em solid #BA9449;
                    }
                }
            }
        }
    }

    @media all and (max-width: ${variables.tabletS}) {
        .slick-slider {
            .slick-arrow {
                display: none !important;
            }
        }
    }
`;
