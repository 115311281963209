import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    background: rgb(241, 241, 241);
    padding: .3125em .375em;
    border-radius: 0.625em;

    .option {
        color: #262626;
        font-size: .875em;
        line-height: 1.214285em;
        margin-right: 0;
        padding: 0.285em 1.43em;
        border-radius: 0.5em;
        border: none;
        cursor: pointer;

        &.selected {
            color: white;
            font-weight: 700;
            background: #BA9449;

            .active-border {
                display: block;
            }

            &:hover {
                color: white;
            }
        }

        &:hover {
            color: #BA9449;
        }
    }

    @media all and (max-width: ${vars.mobileL}) {
        font-size: 120%;

        .option {
            width: 50%;
            text-align: center;
            white-space: nowrap;
        }
    }

    @media all and (max-width: ${vars.mobileM}) {
        width: 100%;
    }

    @media all and (max-width: ${vars.mobileXS}) {
        .option {
            white-space: pre-wrap;
        }
    }
`;
