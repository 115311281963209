import { FunctionComponent, useEffect, useState } from 'react';
import classnames from 'classnames';

import { Option } from 'types/options';
import ListCollection from 'types/redux/ListCollection';

import ProductCategory from 'models/ProductCategory';

import { LIST_MARKUP } from 'consts/redux';
import { getOptionValue } from 'utils/option';

import { list } from 'requests/productCategories';

import SpinnerNew from 'components/layout/SpinnerNew';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutProductCategorySelector: FunctionComponent<Props> = ({ onSelect, selectedOption }) => {
    const [productCategories, setProductCategories] = useState<ListCollection<Option<ProductCategory>>>({ ...LIST_MARKUP });

    useEffect(() => {;
        list()
            .then((collection) => setProductCategories({
                ...collection,
                elements: collection.elements
                    ?.filter(element => element.name !== 'system')
                    ?.map(element => element?.getOption()),
            }))
            .catch((error) => {
                console.error(error);
                setProductCategories({
                    ...LIST_MARKUP,
                    isLoaded: false,
                    isLoading: false,
                    error: true,
                });
            });
    }, []);

    useEffect(() => {
        onSelect(productCategories.elements?.find(option => option.value.name === 'epilation'));
    }, [productCategories, !selectedOption]);

    if (productCategories.isLoading) return (<SpinnerNew />);

    return (
        <StyledComponent className="layout-product-category-selector">
            {productCategories?.elements?.map(option => (
                <div
                    className={classnames(
                        'option',
                        { selected: getOptionValue(selectedOption)?.id === getOptionValue(option)?.id }
                    )}
                    key={getOptionValue(option)}
                    onClick={() => onSelect(option)}
                >
                    {option.label}
                </div>
            ))}
        </StyledComponent>
    );
};

export default LayoutProductCategorySelector;