import { FunctionComponent } from 'react';
import Slider from 'react-slick';
import classnames from 'classnames';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutSlick: FunctionComponent<Props> = ({ children, sliderProps, customClass }) => {
    return (
        <StyledComponent className={(classnames('layout-slick', customClass))}>
            <Slider
                adaptiveHeight={true}
                arrows={true}
                dots={true}
                infinite={false}
                speed={500}
                {...sliderProps}
                lazyLoad="ondemand"
                customPaging={(index: number) => {
                    const displayNumber = ('00' + String(index + 1)).slice(-2);

                    return (
                        <div className="index-container">
                            <div className="index">{displayNumber}</div>
                        </div>
                    );
                }}
            >
                {children}
            </Slider>
        </StyledComponent>
    );
};

export default LayoutSlick;
